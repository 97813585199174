import React from "react";
import "twin.macro";
import { SEO } from "../components";
import {
  BlankPageNoImage,
  LocationBlock,
  ProseWrapper,
} from "../components/common";

const intro = `
Tinnitus is not a disease or an illness, it is the term for hearing sounds in the ears or the head which are not from an external sound source. Tinnitus can sound different to different individuals, a few common descriptions of how tinnitus can sound include but are not limited to; ringing, buzzing, whistling, humming, ticking or hissing. Some people also have a mixture of various sounds or even hear sounds similar to music, words or singing.
<br /> <br />
Tinnitus is rarely an indication of a serious disorder but it is wise to see the GP if symptoms of tinnitus are being experienced.
`;

const Tinnitus = () => {
  return (
    <>
      <SEO title="Tinnitus Assessment" />
      <BlankPageNoImage
        header="Tinnitus Assessment"
        intro={intro}
        ariaLabel="Tinnitus Assessment"
      >
        <ProseWrapper>
          <div>
            <h4>Tinnitus Assessment</h4>
            <p>
              Tinnitus can be caused by many factors and often occurs
              spontaneously. Questions are always asked even in our hearing
              assessments to establish if there is an underlying cause for the
              tinnitus or if onward referral to another professional is
              necessary. However, if the tinnitus has no underlying medical
              connection and simply requires more help, understanding and coping
              mechanisms, our tinnitus assessments will cover this.
            </p>
            <p>
              Tinnitus can be a worrying experience for some individuals
              especially if it has never been experienced before, however, it is
              known that tinnitus can seem worse to the person experiencing it
              when anxious or stressed, therefore, it is important to seek
              advice and help if this is the case.
            </p>
            <p>
              Different terms can be used to describe tinnitus and in a tinnitus
              assessment the type of tinnitus presented will be analysed and
              explained accordingly. The audiologist will help with the
              understanding of tinnitus and work to minimise the impact it has
              along with offering coping strategies.
            </p>
            <p>
              Helpful information regarding tinnitus is also available online on
              the British Tinnitus Association (BTA) website, written by experts
              in the field.
            </p>
            <p>
              Note: We do not recommend using online forums or unofficial
              websites to research or discuss tinnitus as these can often be
              filled with more negative experiences which can hinder somebody’s
              journey to understanding and coping with their own type of
              tinnitus.
            </p>
          </div>
        </ProseWrapper>
        <LocationBlock />
      </BlankPageNoImage>
    </>
  );
};

export default Tinnitus;
